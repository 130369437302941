// VH Check
const test = vhCheck({
    cssVarName: 'vh-offset'
});

// Headroom
const header = document.getElementById("header");
const headroom = new Headroom(header, {
    offset: document.getElementById('header').offsetHeight
});
headroom.init();

// Swiper
let swiper = new Swiper('.swiper-hp', {
    speed: 1000,
    spaceBetween: 0,
    autoplay: {
        delay: 3000,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
});
let thumb = new Swiper('.swiper-thumb', {
    speed: 300,
    slidesPerView: 2,
    spaceBetween: 15,
    breakpoints: {
        768: {
            slidesPerView: 4,
        },
        450: {
            slidesPerView: 3,
        },
    },
    navigation: {
        nextEl: '.next-thumb',
        prevEl: '.prev-thumb',
    },
});
let reservation = new Swiper('.swiper-reservation', {
    speed: 300,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: {
        nextEl: '.next-reservation',
        prevEl: '.prev-reservation',
    },
});

//Burger menu close
document.addEventListener('click', (e) => {
    if (!e.target.closest('.navbar-toggler') && !e.target.closest('.nav-item')) {
        document.querySelector('.navbar-toggler').classList.add('collapsed');
        document.querySelector('.navbar-toggler').setAttribute('aria-expanded', 'false');
        document.querySelector('.navbar-collapse').classList.remove('show');
        document.querySelector('.main-header').classList.remove('expanded');
    }
});

// Scroll up arrow
const scrollUp = document.getElementById("up");

if (scrollUp) {

    scrollUp.addEventListener("click", function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    function scrollUpToggle() {
        if (window.pageYOffset > 100) {
            if (window.oldScroll > window.scrollY) {
                scrollUp.classList.add("active");
            } else {
                scrollUp.classList.remove("active");
            }
        } else {
            scrollUp.classList.remove("active");
        }
        window.oldScroll = window.scrollY;
    }

    window.onscroll = function () {
        scrollUpToggle();
    };

}

// //Outdoor parking Add status class to table row free, reserved, sold
// let tableStatusIndoorParking = document.getElementsByClassName("outdoor-parking__table-info");
// for (let x = 0; x < tableStatusIndoorParking.length; x++) {
//     if (tableStatusIndoorParking[x].getAttribute('data-stav') == "Voľné") {
//         tableStatusIndoorParking[x].classList.add("free")
//     }
//     else if (tableStatusIndoorParking[x].getAttribute('data-stav') == "Rezervované") {
//         tableStatusIndoorParking[x].classList.add("reserved")
//     } else {
//         tableStatusIndoorParking[x].classList.add("sold")
//     }
// }
//
// //Indoor parking Add status class to table row free, reserved, sold
// let tableStatusOutdoorParking = document.getElementsByClassName("indoor-parking__table-info");
// for (let x = 0; x < tableStatusOutdoorParking.length; x++) {
//     if (tableStatusOutdoorParking[x].getAttribute('data-stav') == "Voľné") {
//         tableStatusOutdoorParking[x].classList.add("free")
//     }
//     else if (tableStatusOutdoorParking[x].getAttribute('data-stav') == "Rezervované") {
//         tableStatusOutdoorParking[x].classList.add("reserved")
//     } else {
//         tableStatusOutdoorParking[x].classList.add("sold")
//     }
// }
//
// //Price list Add status class to table row free, reserved, sold
// let tableStatusPriceList = document.getElementsByClassName("price-list__table-info");
// for (let x = 0; x < tableStatusPriceList.length; x++) {
//     if (tableStatusPriceList[x].getAttribute('data-stav') == "Voľné") {
//         tableStatusPriceList[x].classList.add("free")
//     }
//     else if (tableStatusPriceList[x].getAttribute('data-stav') == "Rezervované") {
//         tableStatusPriceList[x].classList.add("reserved")
//     } else {
//         tableStatusPriceList[x].classList.add("sold")
//     }
// }

//Lightbox
lightbox.option({
    'resizeDuration': 200,
});

$(document).ready(function () {

    //Add bg to header if expanded
    $('#navbar-button').click(function () {
        if ($('.navbar-toggler').attr('aria-expanded') === 'false') {
            $('.main-header').addClass('expanded');
        }
        if ($('.navbar-toggler').attr('aria-expanded') === 'true') {
            $('.main-header').removeClass('expanded');
        }
    });

    $(function () {
        // Responsive ImageMapper - Resize Mapster on Window Resize
        if ($('img[usemap]').length) {
            $(window).resize(function () {
                $('img[usemap]').each(function () {
                    $(this).mapster('resize', $(this).closest('.response__item').width());
                });
            });
            $('img[usemap]').each(function () {
                $(this).mapster('resize', $(this).closest('.response__item').width());
            });
        }
        //Resize img mapster after click
        $(document).on("click", ".floor-area", function () {
            $('img[usemap]').each(function () {
                $(this).mapster('resize', $(this).closest('.response__item').width());
            });
        });
        $(document).on("click", ".flat-area", function () {
            $('img[usemap]').each(function () {
                $(this).mapster('resize', $(this).closest('.response__item').width());
            });
        });
    });
});